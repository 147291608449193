import React from "react"
import { Helmet } from "react-helmet"
import Layout from "../../components/layout"
import ColoredHeader from "../../components/coloredHeader"
import FloatingSearch from "../../components/floatingSearch"

import collegeInternsEOG from "../../../static/images/eog-college-interns.png"
import culture_balloons from "../../../static/background_images/culture_balloons.png"
import eeoc_self_print_poster from "../../../static/documents/eeoc_self_print_poster.pdf"

import AccountingIcon from "../../../static/images/hr-recsite-accounting-icon.png"
import EngineeringIcon from "../../../static/images/hr-recsite-engineering-icon.png"
import GeoscienceIcon from "../../../static/images/hr-recsite-geoscience-icon.png"
import InformationSystemsIcon from "../../../static/images/hr-recsite-is-icon.png"
import HumanResourcesIcon from "../../../static/images/hr-recsite-human-resources-icon.png"
// import MarketingIcon from "../../../static/images/hr-recsite/marketing-icon.png"
import PetroleumLandIcon from "../../../static/images/hr-recsite-petroleum-land-icon.png"
import SafetyEnvironmentalIcon from "../../../static/images/hr-recsite-safety-environment-icon.png"
// const SupplyChainIcon from "../../../static/images/hr-recsite/supply-chain-icon.png"

const RecruitingPDF = `/documents/EOG-Resources-Internship-Opportunities-2024.pdf?${new Date().getTime()}`

export default function RecruitingEvents(props) {
  const themeColor = "#ff6d00"

  return (
    <Layout>
      <Helmet>
        <title>Recruiting Events – EOG Resources, Inc.</title>
      </Helmet>
      <ColoredHeader
        title="CULTURE"
        header="College Recruiting"
        overlayColor1="#ffaa00"
        overlayColor2={themeColor}
        lineColor="#ffc146"
        bgImageSRC={culture_balloons}
      />
      <div className="max-width-container absolute desktop-only">
        <FloatingSearch clear />
      </div>

      <section
        className="page recruiting-events-page ps-section"
        style={{ paddingTop: 0 }}
      >
        <div className="max-width-container">
          <img
            src={collegeInternsEOG}
            alt="EOG College Interns"
            style={{ maxWidth: "100%", paddingTop: 20, paddingBottom: 0 }}
          />
          <p>
            EOG offers an annual summer internship program that plays a critical
            role in our college recruiting. These internships, with the
            potential for full-time employment after graduation, are offered to
            qualified students who are pursuing careers in engineering,
            geoscience, computer science, energy management degrees, information
            technology, or business. Interns at EOG work on projects that not
            only provide valuable hands-on work experience, but also firsthand
            knowledge and insight into one of the most dynamic oil and gas
            companies in the United States. Internship opportunities may be
            offered in any of our{" "}
            <a href="/company/areas-of-operation">division offices</a>.
          </p>

          <div className="dxce-card" style={{ paddingTop: 0 }}>
            <p>
              Examples of projects that interns have worked on and contributed
              to include the following:
            </p>
            <ul className="dxce-card-list">
              <li>Design and pilot use of drones to detect methane leaks</li>
              <li>
                Development of a hydraulic fracturing fleet strategy to reduce
                GHG emissions and capital spending
              </li>
              <li>
                Creation of a real-time tool to evaluate select financial
                metrics from completion operations
              </li>
              <li>Exploration and mapping of a new play</li>
            </ul>
          </div>

          <p>
            We generally offer summer internship opportunities in various areas
            of our company. More information about our potential internship
            opportunities, as well as minimum requirements, can be found{" "}
            <a href={RecruitingPDF} target="_pdf">
              here
            </a>
            .
          </p>

          <ul className="intern-projects first-row">
            <li>
              <h4>
                <img src={EngineeringIcon} alt="Engineering Icon" />
                Engineering
              </h4>
              <p>
                Hands-on experience on drilling rigs, well locations,
                facilities, or lease pumping routes
              </p>
            </li>
            <li>
              <h4>
                <img src={GeoscienceIcon} alt="Geoscience Icon" />
                Geoscience
              </h4>
              <p>
                Utilize skills in stratigraphy, sedimentology, structural
                geology, log analysis, and geophysical interpretation
              </p>
            </li>
            <li>
              <h4>
                <img src={PetroleumLandIcon} alt="Petroleum Land Icon" />
                Petroleum Land
              </h4>
              <p>
                Explore aspects of land management from prospect generation to
                first sales, negotiation, contract analysis, and mapping
              </p>
            </li>
            <li>
              <h4>
                <img src={AccountingIcon} alt="Accounting Icon" />
                Accounting
              </h4>
              <p>
                Gain experience in operations, division support, internal
                controls, or international accounting
              </p>
            </li>
          </ul>
          <ul className="intern-projects second-row">
            <li>
              <h4>
                <img
                  src={SafetyEnvironmentalIcon}
                  alt="Safety Environmental Icon"
                />
                Safety & Environmental
              </h4>
              <p>
                Provide support for training, emission reduction products,
                various permitting, compliance, and auditing projects
              </p>
            </li>
            <li>
              <h4>
                <img
                  src={InformationSystemsIcon}
                  alt="Information Systems Icon"
                />
                Information Systems
              </h4>
              <p>
                Support development of in-house applications, analyze customer
                requests, and document for development and enhancement
              </p>
            </li>
            <li>
              <h4>
                <img src={HumanResourcesIcon} alt="Human Resources Icon" />
                Human Resources
              </h4>
              <p>
                Support HR information systems, compensation, benefits,
                recruiting, or employee relations
              </p>
            </li>
          </ul>
        </div>
      </section>
      <section className="page recruiting-events-page schedule-section max-width-container">
        <h2 class="elementor-heading-title elementor-size-default">
          2024 Fall Event Calendar
        </h2>

        <p>
          Ready for the EOG experience? Check the schedule below to see when we
          will be on your campus! Don't see your school or event? Revisit our
          website as new dates are continuously added.
        </p>

        <div className="cols">

          <div className="col dxce-card">
            <p>
              <strong>Colorado School of Mines</strong>
            </p>
            <ul className="dxce-card-list">
              <li>9/10: Career Day</li>
              <li>9/10: EOG Info Session</li>
              <li>9/11: Engineering & Geoscience Interviews</li>
            </ul>

            <p>
              <strong>Houston Geologic Society</strong>
            </p>
            <ul className="dxce-card-list">
              <li>9/9: Student Expo</li>
              <li>9/10: Geoscience Interviews</li>
            </ul>

            <p>
              <strong>Montana Tech</strong>
            </p>
            <ul className="dxce-card-list">
              <li>9/19: Career Fair</li>
              <li>9/20: Engineering Interviews</li>
            </ul>

            <p>
              <strong>Rice University</strong>
            </p>
            <ul className="dxce-card-list">
              <li>9/24: Geoscience Career Fair</li>
              <li>9/30: Geoscience Info Session</li>
              <li>10/1: Geoscience Interviews</li>
            </ul>

            <p>
              <strong>Texas A&M University</strong>
            </p>
            <ul className="dxce-card-list">
              <li>8/28: SPE Company Showcase</li>
              <li>8/29: SPE Career Enhancement Event</li>
              <li>9/12: Engineering Interviews</li>
              <li>9/18: Sciences Career Fair</li>
              <li>9/24: Geoscience Info Session</li>
              <li>9/25: Geoscience Interviews</li>
              <li>11/12: SPE General Meeting</li>
            </ul>

            <p>
              <strong>Texas Tech University</strong>
            </p>
            <ul className="dxce-card-list">
              <li>9/12: Meet the Industry</li>
              <li>9/18: Engineering Career Fair</li>
              <li>9/18: SPE/AADE General Meeting</li>
              <li>9/19: Engineering Interviews</li>
              <li>10/14: Land Interviews</li>
            </ul>


          </div>


          <div className="col dxce-card">
            <p>
              <strong>University of Houston</strong>
            </p>
            <ul className="dxce-card-list">
              <li>9/10: NSM Career Fair</li>
              <li>9/17: IS Info Session</li>
              <li>9/18: IS Interviews</li>
              <li>9/26: Geoscience Info Session</li>
              <li>9/27: Geoscience Interviews</li>
            </ul>

            <p>
              <strong>University of Oklahoma</strong>
            </p>
            <ul className="dxce-card-list">
              <li>9/11: Sooner Showcase</li>
              <li>9/12: Engineering Career Fair</li>
              <li>9/12: Land Interviews</li>
              <li>9/13: Engineering Interviews</li>
              <li>9/26: Geoscience Info Session</li>
              <li>9/27: Geoscience Interviews</li>
            </ul>

            <p>
              <strong>University of Texas at Austin</strong>
            </p>
            <ul className="dxce-card-list">
              <li>9/10: Engineering Expo</li>
              <li>9/19: Geoscience Career Fair</li>
              <li>9/23: Geoscience Info Session</li>
              <li>9/24: Geoscience Interviews</li>
            </ul>

            <p>
              <strong>University of Texas at Dallas</strong>
            </p>
            <ul className="dxce-card-list">
              <li>9/19: JCS Engineering & Computer Science Career Fair</li>
              <li>10/1: ACM Workshop</li>
              <li>10/2: IS Interviews</li>
            </ul>

            <p>
              <strong>University of Wyoming</strong>
            </p>
            <ul className="dxce-card-list">
              <li>9/18: STEM Career Fair</li>
              <li>9/19: Engineering Interviews</li>
            </ul>
          </div>
        </div>
      </section>
      <section className="page recruiting-events-page equal-opportunities-section max-width-container last-page">
        <div>
          <p>
            EOG Resources, Inc. is proud to be an equal opportunity workplace
            and is an affirmative action employer. All qualified applicants will
            receive consideration for employment without regard to sex, race,
            color, age, religion, national origin, physical or mental
            disability, pregnancy, protected veteran status, genetic
            information, sexual orientation, gender identity or any other
            characteristics protected by law.
          </p>
          <p>
            <a href={eeoc_self_print_poster} target="_blank" rel="noreferrer">
              <span style={{ textDecoration: "underline" }}>
                Equal Employment is the Law
              </span>
            </a>
          </p>
          <p>
            Requests for Accommodation
            <br />
            Individuals with a disability who wish to request an accommodation
            to complete an employment application, please{" "}
            <a
              href="mailto:Application_Accommodation@eogresources.com"
              target="_blank"
              rel="noreferrer"
            >
              click here
            </a>{" "}
            or call (713) 651-6694.
          </p>
          <p style={{ color: themeColor, marginBottom: "6rem" }}>
            FRAUD ALERT ** If you are responding to a recruiting email, please
            ensure that the email has the domain @eogresources.com; if the email
            has a different domain DO NOT PROVIDE ANY INFORMATION. Instead,
            please contact Recruiting at 713-651-6694.
          </p>
        </div>
      </section>
    </Layout>
  )
}
